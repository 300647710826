import { Component } from '@angular/core';
import { ProductService } from "../../product/model/product.service";
import { ActivatedRoute } from "@angular/router";
import { Product } from "../../product/model/product-api";
import { ProductDetailsService } from "../../product/model/product-details.service";
import { ProductFacadeService } from "../../product/model/product-facade.service";
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { switchMap, EMPTY, Observable } from 'rxjs';

@Component({
  selector: 'app-document-product-details',
  templateUrl: './document-product-details.component.html',
  styleUrls: ['./document-product-details.component.scss'],
  providers: [ProductFacadeService],
  standalone: false
})
export class DocumentProductDetailsComponent {
  reloading: boolean = false;
  product: Product | undefined;
  productId: string = '';
  productDetailsRoute = false;

  constructor(
    private productService: ProductService,
    private productDetailsService: ProductDetailsService,
    private route: ActivatedRoute
  ) {
    this.route.queryParams.pipe(
      switchMap(params => {
        this.productId = params.product ?? '';
        this.productDetailsRoute = this.productDetailsService.isProductDetailsRoute();
        return this.loadProduct();
      }),
      takeUntilDestroyed()
    ).subscribe(product => this.handleProduct(product));
  }

  private loadProduct(): Observable<Product> {
    if (!this.productId) return EMPTY;
    this.reloading = true;
    
    return this.productDetailsRoute 
      ? this.productDetailsService.getProduct(this.productId) ?? EMPTY
      : this.productService.getProduct(this.productId);
  }

  private handleProduct(p: Product) {
    this.product = p;
    this.reloading = false;
  }
}
