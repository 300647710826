import { Component, Input, output, signal } from '@angular/core'
import { HomeService } from '../model/home.service'
import { Observable } from "rxjs"
import { MainMenuActionGroupComponent } from "../main-menu-action-group/main-menu-action-group.component"
import { MatDividerModule } from '@angular/material/divider'
import { MatToolbarModule } from '@angular/material/toolbar'
import { MatIconModule } from '@angular/material/icon'
import { AppService } from 'src/app/app.service'

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrl: './main-menu.component.scss',
  imports: [MainMenuActionGroupComponent, MatDividerModule, MatToolbarModule, MatIconModule],
  standalone: true
})
export class MainMenuComponent {
  @Input() set collapsed(value: boolean) {
    this._collapsed.set(value);
  }
  get collapsed(): boolean {
    return this._collapsed();
  }
  private _collapsed = signal(false);
  collapsedChange = output<boolean>();

  isHandset$: Observable<boolean>

  constructor(public service: HomeService, private appService: AppService) {
    this.isHandset$ = this.appService.isMobile$
  }

  ngOnInit() {
    this.isHandset$.subscribe(h => {
      if (h) this.setCollapsed(true)
    })
  }

  toggleCollapsed() {
    this.setCollapsed(!this.collapsed)
  }

  setCollapsed(value: boolean) {
    this._collapsed.set(value)
    this.collapsedChange.emit(value)
  }
}
