import { Component, input, Input } from '@angular/core';
import { MainMenuActionGroup } from '../model/main-menu-action';
import { HomeService } from '../model/home.service';
import { MainMenuActionComponent } from "../main-menu-action/main-menu-action.component";
import { MatDividerModule } from '@angular/material/divider';

@Component({
    selector: 'app-main-menu-action-group',
    templateUrl: './main-menu-action-group.component.html',
    styleUrl: './main-menu-action-group.component.scss',
    imports: [MainMenuActionComponent, MatDividerModule],
})
export class MainMenuActionGroupComponent {
  @Input() data!: MainMenuActionGroup;
  collapsed = input(false);

  constructor(public service: HomeService) {}
}
