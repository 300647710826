import { Component, input, Input, OnChanges, OnDestroy, signal } from '@angular/core'
import { MainMenuAction, MainMenuActionStatus } from '../model/main-menu-action'
import { Subscription } from 'rxjs'
import { TranslatePipe } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-main-menu-action',
  imports: [TranslatePipe, MatIconModule, RouterLink],
  templateUrl: './main-menu-action.component.html',
  styleUrls: ['./main-menu-action.component.scss'] // Fixed typo from styleUrl to styleUrls
})
export class MainMenuActionComponent implements OnChanges, OnDestroy {
  @Input() data!: MainMenuAction

  collapsed = input(false);
  status: MainMenuActionStatus | null = null;
  private subscription: Subscription | null = null;

  ngOnChanges(): void {
    this.resetSubscription()

    if (this.data.status) {
      this.subscription = this.data.status.subscribe({
        next: (d) => this.handleStatusChanged(d)
      })
    }
  }

  ngOnDestroy(): void {
    this.resetSubscription()
  }

  private resetSubscription(): void {
    if (this.subscription) {
      this.subscription.unsubscribe()
      this.subscription = null
    }
    this.status = null
  }

  private handleStatusChanged(d: MainMenuActionStatus): void {
    this.status = d
  }
}
