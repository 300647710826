import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FeedbackComponent} from './feedback/feedback.component';
import {ReactiveFormsModule} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import {MaterialModule} from "../../material/material.module";
import {FeedbackDialogComponent} from './feedback-dialog/feedback-dialog.component';
import {FeedbackButtonComponent} from './feedback-button/feedback-button.component';
import {FloatingButtonComponent} from "../../component/floating-button/floating-button.component";


@NgModule({
  declarations: [
    FeedbackComponent,
    FeedbackDialogComponent,
    FeedbackButtonComponent
  ],
  exports: [
    FeedbackComponent,
    FeedbackButtonComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    MaterialModule,
    FloatingButtonComponent
  ]
})
export class FeedbackModule {
}
