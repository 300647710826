<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer [attr.role]="(isMobile()) ? 'dialog' : 'navigation'" [mode]="isMobile() ? 'over' : 'side'" [opened]="!isMobile()"
               [style.width]="sidenavWidth()"
               class="sidenav"
               fixedInViewport
  >
    <app-main-menu [(collapsed)]="collapsed" class="flex flex-col h-full"></app-main-menu>
  </mat-sidenav>
  <mat-sidenav-content [style.margin-left]="(isMobile()) ? '0px' : sidenavWidth()">
    <div class="flex flex-col justify-start mw-100 min-h-screen">
      <app-main-header (toggleDrawer)="toggleDrawer()" [isMobile]="isMobile()!"></app-main-header>
      <div class="flex-auto">
        <router-outlet></router-outlet>
      </div>
      <app-main-footer class="imprint"></app-main-footer>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
