import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home/home.component';
import { MainHeaderComponent } from './main-header/main-header.component';
import { MaterialModule } from "../material/material.module";
import { MainFooterComponent } from './main-footer/main-footer.component';
import { TranslateModule } from "@ngx-translate/core";
import { SearchModule } from "../search/search.module";
import { MainHeaderActionsComponent } from './main-header-actions/main-header-actions.component';
import { MainHeaderActionComponent } from './main-header-action/main-header-action.component';
import { MainHeaderAccountComponent } from './main-header-account/main-header-account.component';
import { HomeService } from "./model/home.service";
import { MainSearchComponent } from './main-search/main-search.component';
import { MainMenuComponent } from './main-menu/main-menu.component';

@NgModule({
  declarations: [
    HomeComponent,
    MainHeaderComponent,
    MainFooterComponent,
    MainHeaderActionsComponent,
    MainHeaderActionComponent,
    MainHeaderAccountComponent,
    MainSearchComponent
  ],
  exports: [
    HomeComponent
  ],
  imports: [
    MainMenuComponent,
    CommonModule,
    HomeRoutingModule,
    MaterialModule,
    TranslateModule,
    SearchModule
  ],
  providers: [
    HomeService
  ]
})
export class HomeModule {
}
