import {Component, EventEmitter, Input, OnChanges, SimpleChanges, Output} from '@angular/core';
import {ResourceHierarchyNode} from "../../../../hierarchy/model/hierarchy-api";
import {Equipment, EquipmentInfo} from "../../../model/equipment-api";
import {PageEvent} from "@angular/material/paginator";
import {EquipmentService} from "../../../model/equipment.service";
import {System} from "../../../../system/model/system-api";
import {EMPTY} from "rxjs";
import {tap, finalize, switchMap} from "rxjs/operators";

@Component({
    selector: 'app-operational-graph-equipment-list',
    templateUrl: './operational-graph-equipment-list.component.html',
    styleUrl: './operational-graph-equipment-list.component.scss',
    standalone: false
})
export class OperationalGraphEquipmentListComponent implements OnChanges {

  @Input() systemData!: System;
  @Input() selectedEquipmentData: Equipment[] = [];
  @Output() equipmentSelect: EventEmitter<Equipment> = new EventEmitter()

  system: System | undefined
  hierarchy: ResourceHierarchyNode[] = []
  equipments: EquipmentInfo[] = []
  selectedEquipments: Equipment[] = []
  loading: boolean = false
  pageIndex: number = 0
  pageSize: number = 5
  totalSize: number = -1

  constructor(
    private service: EquipmentService
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['systemData'] && changes['systemData'].currentValue) {
      this.system = changes['systemData'].currentValue;
      this.loading = true;
      this.loadSystemEquipments(this.pageIndex, this.pageSize);
    }

    if (changes['selectedEquipmentData'] && changes['selectedEquipmentData'].currentValue?.length) {
      this.selectedEquipments = changes['selectedEquipmentData'].currentValue;
    }
  }

  onEquipmentSelect(equipment: Equipment) {
    this.equipmentSelect.emit(equipment)
  }

  handlePaginatorEvent(event: PageEvent) {
    this.loading = true
    this.loadSystemEquipments(event.pageIndex, event.pageSize)
  }

  checkStatus(equipment: Equipment) {
    return this.selectedEquipments.find(e => e.id === equipment.id) !== undefined
  }

  private loadSystemEquipments(page: number, size: number) {
    if (!this.system || this.loading) return;
    
    this.loading = true;
    this.service.getSystemEquipments(this.system.id, page, size).pipe(
      tap(data => {
        this.equipments = data.content;
        this.pageIndex = data.pageable.number;
        this.pageSize = data.pageable.size;
        this.totalSize = data.totalSize;
      }),
      switchMap(() => this.equipments.length 
        ? this.service.getPath(this.equipments[0].equipment.id)
        : EMPTY),
      tap(data => this.hierarchy = data),
      finalize(() => this.loading = false)
    ).subscribe();
  }
}
