import {Component} from '@angular/core';
import {HomeService} from "../model/home.service";
import {distinctUntilChanged, filter, debounceTime, tap} from "rxjs/operators";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {Subject} from "rxjs";

@Component({
    selector: 'app-main-search',
    templateUrl: './main-search.component.html',
    styleUrl: './main-search.component.scss',
    standalone: false
})
export class MainSearchComponent {

  private readonly searchChangeSubject = new Subject<string>();

  constructor(public service: HomeService) {
    this.searchChangeSubject.pipe(
      filter(Boolean),
      distinctUntilChanged(),
      debounceTime(2000),
      tap(text => this.search(text)),
      takeUntilDestroyed()
    ).subscribe()
  }

  onSearchChange(value: string) {
    this.searchChangeSubject.next(value)
  }

  search(query: string) {
    this.service.executeSearch(query)
  }

  reset() {
    this.service.executeSearch('')
  }
}
