import { NgModule, inject, provideAppInitializer } from '@angular/core'
import { CommonModule } from '@angular/common'
import { provideKeycloak } from "keycloak-angular"
import { canActivateAuthRole } from "./auth.guard"
import { AuthService } from "./auth.service"
import { environment } from 'src/environments/environment'
import { provideKeycloakAngular } from './keycloak.config'


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
  ],
  providers: [
    provideKeycloakAngular(),
    // canActivateAuthRole,
    AuthService
  ]
})
export class AuthModule {
}
