import {Component} from '@angular/core';
import {EquipmentService} from "../../model/equipment.service";
import {ActivatedRoute} from "@angular/router";
import {ThingTemplateService} from "../../../thing-template/model/thing-template.service";
import {forkJoin, switchMap, tap, finalize, EMPTY} from "rxjs";
import {OperationalGraphService} from "../model/operational-graph.service";
import {ModuleService} from "../../../module/model/module.service";
import {DateTime} from "luxon";
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
@Component({
    selector: 'app-operational-graph',
    templateUrl: './operational-graph.component.html',
    styleUrl: './operational-graph.component.scss',
    standalone: false
})
export class OperationalGraphComponent {


  reloading: boolean = false


  constructor(
    public service: OperationalGraphService,
    private equipmentService: EquipmentService,
    private thingTemplateService: ThingTemplateService,
    private moduleService: ModuleService,
    private route: ActivatedRoute
  ) {
    this.service.reset()
    this.init()
  }

  private init() {
    this.route.params.pipe(
      switchMap((params) => {
        const equipmentId = Number(params['id'])
        if (!equipmentId) return EMPTY
        return this.loadEquipment(equipmentId)
      }),
      takeUntilDestroyed()
    ).subscribe();

    this.route.queryParams.pipe(
      tap(params => this.handleQueryParams(params)),
      takeUntilDestroyed()
    ).subscribe();

    this.service.changeEvent.pipe(
      tap(_ => {
        if (this.service.equipments.length >= 2) this.changeChartsHeight()
      }),
      takeUntilDestroyed()
    ).subscribe();
  }

  private loadEquipment(id: number) {
    this.reloading = true;
    return forkJoin([
      this.equipmentService.getEquipment(id),
      this.thingTemplateService.getEquipmentTemplate(id),
      this.moduleService.findByEquipmentId(id)
    ]).pipe(
      tap(([equipment, template, modules]) => {
        this.service.addEntry(equipment, template)
        this.service.predefineProperties(modules.content)
      }),
      finalize(() => this.reloading = false)
    );
  }

  private handleQueryParams(params: any) {
    const start = params['start']
    const end = params['end']

    if (!start && !end) return

    const startDate = DateTime.fromISO(start);
    const endDate = DateTime.fromISO(end);

    if (startDate.isValid && endDate.isValid) {
      this.service.range.setValue({
        start: startDate.toISO(),
        end: endDate.toISO()
      });
    }
  }

  private changeChartsHeight() {
    const chartContainer = Array.from(document.getElementsByClassName('chart-container')).map(e => e as HTMLElement)
    chartContainer.map(e => e.style.height = '500px')
  }
}
