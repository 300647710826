import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Equipment} from "../../model/equipment-api";
import {EquipmentService} from "../../model/equipment.service";
import {ResourceHierarchyNode} from "../../../hierarchy/model/hierarchy-api";
import {System} from "../../../system/model/system-api";
import {EMPTY, switchMap, tap} from "rxjs";
import {OperationalGraphService} from "../model/operational-graph.service";
import {SystemService} from "../../../system/model/system.service";

@Component({
    selector: 'app-operational-graph-add-equipment-dialog',
    templateUrl: './operational-graph-add-equipment-dialog.component.html',
    styleUrl: './operational-graph-add-equipment-dialog.component.scss',
    standalone: false
})
export class OperationalGraphAddEquipmentDialogComponent implements OnInit {

  selectedEquipments: Equipment[] = []
  siteId: number = 0
  isSystemView: boolean = false

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Equipment,
    private dialogRef: MatDialogRef<OperationalGraphAddEquipmentDialogComponent>,
    private equipmentService: EquipmentService,
    private systemService: SystemService,
    public operationalGraphService: OperationalGraphService
  ) { }

  ngOnInit() {
    this.loadEquipmentPath()
  }

  select() {
    this.dialogRef.close(this.selectedEquipments)
  }

  cancel() {
    this.dialogRef.close()
  }

  handleSelectedEquipment(result: Equipment) {
    if (this.selectedEquipments.find(e => e.id === result.id) !== undefined) {
      const idx = this.selectedEquipments.indexOf(result)
      this.selectedEquipments.splice(idx, 1)
      return
    }

    this.selectedEquipments.push(result)
  }

  handleSelectedSystems(result: System[]) {
    this.isSystemView = false
    if (!result.length) return
    this.operationalGraphService.systems = [...result]
  }

  private loadEquipmentPath() {
    this.equipmentService.getPath(this.data.id)
      .pipe(
        switchMap(data => this.handlePathData(data))
      ).subscribe()
  }

  private handlePathData(data: ResourceHierarchyNode[]) {
    this.siteId = data[0].resource.id
    this.selectedEquipments = [...this.operationalGraphService.equipments]
    const systemId = data[0].children[0].resource.id
    if (this.operationalGraphService.systems.length) return EMPTY
    return this.systemService.getSystem(systemId).pipe(tap(data => this.operationalGraphService.systems.push(data)))
  }
}


