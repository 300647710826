import { Component, DestroyRef, inject } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { ReportService } from "../model/report.service";
import { OperationalReportService } from "../model/operational-report.service";
import { EMPTY, forkJoin, switchMap } from "rxjs";
import { EquipmentOperationalReport } from "../model/operational-report-api";
import { ThingTemplateService } from "../../../thing-template/model/thing-template.service";
import { saveAs } from "file-saver";
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { map, tap } from 'rxjs';

@Component({
    selector: 'app-operational-report',
    templateUrl: './operational-report.component.html',
    styleUrl: './operational-report.component.scss',
    standalone: false
})
export class OperationalReportComponent {
  private readonly destroyRef = inject(DestroyRef);
  report: EquipmentOperationalReport | undefined
  reloading: boolean = false

  constructor(
    private service: ReportService,
    public operationalReportService: OperationalReportService,
    private thingTemplateService: ThingTemplateService,
    private route: ActivatedRoute,
  ) {
    this.load()
  }

  private load() {
    this.route.params.pipe(
      takeUntilDestroyed(this.destroyRef),
      tap(() => this.reloading = true),
      map(params => Number(params['id'])),
      tap(id => this.operationalReportService.equipmentId = id),
      switchMap(id => this.loadReport(id))
    ).subscribe();

    this.operationalReportService.changeEvent.pipe(
      takeUntilDestroyed(this.destroyRef),
      switchMap(() => this.loadReport(this.operationalReportService.equipmentId))
    ).subscribe();

    this.operationalReportService.downloadEvent.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(() => this.downloadReport());
  }

  private loadReport(equipmentId: number) {
    if (!equipmentId) {
      this.reloading = false;
      return EMPTY;
    }

    const request = this.operationalReportService.createReportRequest();
    
    return forkJoin([
      this.service.getEquipmentReport(equipmentId, request),
      this.thingTemplateService.getEquipmentTemplate(equipmentId)
    ]).pipe(
      tap(([report, template]) => {
        if (!report) {
          this.reloading = false;
          return;
        }
        this.report = report;
        this.reloading = false;
        this.operationalReportService.template = template;
      })
    );
  }

  private downloadReport() {
    if (!this.report) return
    const blob = JSON.stringify(this.report)
    const data = new Blob([blob], {
      type: 'application/json'
    })
    saveAs(data, 'operational-report-' + this.report.info.equipment.id + '.json')
  }
}
