import {Component} from '@angular/core';
import {AlarmInfo} from "../../alarm/model/alarm-api";
import {EquipmentInfo} from "../model/equipment-api";
import {FormControl, FormGroup} from "@angular/forms";
import {AlarmService} from "../../alarm/model/alarm.service";
import {ActivatedRoute} from "@angular/router";
import {PageEvent} from "@angular/material/paginator";
import {Page} from "../../utils/page";
import {EquipmentService} from "../model/equipment.service";
import {TranslateService} from "@ngx-translate/core";
import {DateTime} from "luxon";
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { filter, finalize, switchMap, tap } from 'rxjs/operators'
import { EMPTY } from 'rxjs';

@Component({
    selector: 'app-equipment-details-alarms',
    templateUrl: './equipment-details-alarms.component.html',
    styleUrls: ['./equipment-details-alarms.component.scss'],
    standalone: false
})
export class EquipmentDetailsAlarmsComponent {

  equipment: EquipmentInfo | undefined
  reloading: boolean = false
  data: Page<AlarmInfo> | undefined
  pageSize = 10
  pageIndex: number = 0

  selectedTime: string = '1'
  range = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null)
  });

  constructor(
    public service: EquipmentService,
    private alarmService: AlarmService,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService
  ) {
    this.activatedRoute.paramMap.pipe(
      filter(paramsMap => !!paramsMap.get('id')),
      switchMap(paramsMap => {
        const equipmentId = Number(paramsMap.get('id'))
        return this.service.getInfo(equipmentId)
      }),
      tap(equipment => {
        this.equipment = equipment
        if (this.equipment.equipment.activated) {
          this.loadAlarms(this.pageIndex, this.pageSize).subscribe()
        }
      }),
      takeUntilDestroyed()
    ).subscribe()

    this.range.valueChanges.pipe(
      filter(dates => !!dates.start && !!dates.end),
      tap(() => this.filterAlarmsOnRangeChange()),
      takeUntilDestroyed()
    ).subscribe()
  }

  filterAlarmsOnPreset() {
    if (!this.selectedTime) return
    this.range.reset()
    this.loadAlarms(0, this.pageSize).subscribe()
  }

  filterAlarmsOnRangeChange() {
    this.selectedTime = ''
    this.loadAlarms(0, this.pageSize).subscribe()
  }


  handlePaginatorEvent(event: PageEvent) {
    this.loadAlarms(event.pageIndex, event.pageSize).subscribe();
  }


  private loadAlarms(page: number, size: number) {
    if (!this.equipment) return EMPTY;
    if (this.reloading) return EMPTY;

    this.reloading = true;

    let equipmentId = this.equipment.equipment.id;

    const now = DateTime.now().toUTC()
    const timestampUnit = this.getTimestamp(this.selectedTime);
    let start = timestampUnit ? now.minus({[timestampUnit]: 1}).toISO() : now.toISO();
    let end = now.toISO();

    let startDate = this.range.get('start')?.value
    let endDate = this.range.get('end')?.value

    if (startDate && endDate) {
      start = DateTime.fromJSDate(startDate).toISO() ?? ''
      end = DateTime.fromJSDate(endDate).toISO() ?? ''
    } else if (this.selectedTime) {
      const amount = parseInt(this.selectedTime, 10)
      const unit = this.getTimestamp(this.selectedTime)

      if (unit) {
        start = now.minus({[unit]: amount}).toISO()
      }
      end = now.toISO()
    }

    return this.alarmService.search(equipmentId, start, end, this.translate.currentLang, page, size)
    .pipe(
      tap(d => this.handleData(d)),
      finalize(() => this.reloading = false)
    )
  }

  private getTimestamp(time: string): string | undefined {
    switch (time) {
      case '28':
      case '7':
      case '1':
        return 'days'
      default:
        return undefined
    }
  }

  private handleData(data: Page<AlarmInfo>) {
    this.data = data
    this.pageIndex = data.pageable.number
    this.pageSize = data.pageable.size
    this.reloading = false
  }

}
