import { Component, DestroyRef, inject } from '@angular/core'
import { OperationalGraphService } from "../model/operational-graph.service"
import { MatDialog } from "@angular/material/dialog"
import { OperationGraphPropertySelectorComponent } from "../operation-graph-property-selector/operation-graph-property-selector.component"
import { FormControl } from "@angular/forms"
import { interval } from "rxjs"
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { OperationalGraphAddEquipmentDialogComponent } from "../operational-graph-add-equipment-dialog/operational-graph-add-equipment-dialog.component"
import { MatSelectChange } from "@angular/material/select"
import { saveAs } from "file-saver"

@Component({
  selector: 'app-operational-graph-header',
  templateUrl: './operational-graph-header.component.html',
  styleUrl: './operational-graph-header.component.scss',
  standalone: false
})
export class OperationalGraphHeaderComponent {
  private readonly destroyRef = inject(DestroyRef);
  refreshControl = new FormControl<number>(0);
  refreshSelection: number[] = [0, 5, 10]
  systemTime: boolean = false
  downloading = false

  constructor(
    public service: OperationalGraphService,
    private dialog: MatDialog
  ) {
  }

  onOpenPropertySelection() {
    let dialogRef = this.dialog.open(OperationGraphPropertySelectorComponent, {
      disableClose: true,
      maxHeight: '85vh'
    })

    dialogRef.afterClosed().subscribe(result => {
      if (!result.length) return
      this.service.onPropertySelect(result)
    })
  }

  openAddEquipmentDialog() {
    const dialogRef = this.dialog.open(OperationalGraphAddEquipmentDialogComponent, {
      width: '40vw',
      maxHeight: '90vh',
      disableClose: true,
      data: this.service.equipments[0]
    })

    dialogRef.afterClosed().subscribe(result => {
      if (!result.length) return
      this.service.equipments = [...result]
      this.service.loadTemplates()
      this.refresh()
    })
  }

  refresh() {
    this.service.refresh()
  }

  changeTimer(event: MatSelectChange) {
    let value = event.value ?? 0
    let period = value * 60 * 1000

    if (value > 0) {
      interval(period).pipe(
        takeUntilDestroyed(this.destroyRef)
      ).subscribe(() => this.refresh())
    }
  }

  toggleSystemTime() {
    this.systemTime = !this.systemTime
    this.service.switchSystemTime(this.systemTime)
  }

  download() {
    this.downloading = true
    this.service.download(this.service.equipments[0].id).subscribe(blob => {
      const data = new Blob([blob.body], { type: 'application/zip' })
      saveAs(data, `operational-data-${this.service.equipments[0].id}.zip`)
      this.downloading = false
    })
  }

}
