import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { DocumentBoardComponent } from "./document-board/document-board.component"
import { DocumentProductDetailsComponent } from "./document-product-details/document-product-details.component"
import { canActivateAuthRole } from "../../auth/auth.guard"
import { BETA_ACCESS, PUBLIC_ACCESS } from "../../auth/roles"

const routes: Routes = [
  {
    path: '',
    canActivate: [canActivateAuthRole],
    data: { roles: [BETA_ACCESS, PUBLIC_ACCESS] },
    component: DocumentBoardComponent
  },
  {
    path: 'details',
    canActivate: [canActivateAuthRole],
    data: { roles: [BETA_ACCESS, PUBLIC_ACCESS] },
    component: DocumentProductDetailsComponent
  }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DocumentRoutingModule {
}
