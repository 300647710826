import {
  createInterceptorCondition,
  INCLUDE_BEARER_TOKEN_INTERCEPTOR_CONFIG,
  IncludeBearerTokenCondition,
  provideKeycloak,
  withAutoRefreshToken,
  AutoRefreshTokenService,
  UserActivityService
} from 'keycloak-angular'
import {environment} from 'src/environments/environment'


let regex: RegExp = /^(https?:\/\/[^\/]+)?api(\/.*)?$/i
const condition = createInterceptorCondition<IncludeBearerTokenCondition>({
  urlPattern: regex,
  bearerPrefix: 'Bearer'
})

export const provideKeycloakAngular = () => {
  return provideKeycloak({
    config: {
      url: environment.authUrl,
      realm: 'bdn',
      clientId: 'bdn-ui'
    },
    initOptions: {
      onLoad: 'check-sso',
      // onLoad: 'login-required',
      pkceMethod: 'S256',
      silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html'
    },
    features: [
      withAutoRefreshToken({
        onInactivityTimeout: 'none',
        sessionTimeout: 600000
      })
    ],
    providers: [
      AutoRefreshTokenService,
      UserActivityService,
      {
        provide: INCLUDE_BEARER_TOKEN_INTERCEPTOR_CONFIG,
        useValue: [condition]
      }
    ]
  })
}
