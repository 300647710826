import { Component } from '@angular/core'
import { Observable } from "rxjs"
import { AuthService } from "./auth/auth.service"
import { TranslateService } from "@ngx-translate/core"
import { MatSelectChange } from "@angular/material/select"
import { CompanyService } from "./company/model/company.service"
import { Company, CompanyValidationResult } from "./company/model/company-api"
import { MatDialog, MatDialogRef } from "@angular/material/dialog"
import { LoadingDialogComponent } from "./loading-dialog.component"
import { environment } from "../environments/environment"
import { Meta } from "@angular/platform-browser"
import { ConsentService } from "./consent/consent.service"
import { IconService } from "./icon.service"
import { Settings } from 'luxon'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { AppService } from './app.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: false
})
export class AppComponent {

  isHandset$: Observable<boolean>

  lang = this.translate.getBrowserLang()
  embedded: boolean = true
  company: Company | undefined
  protected readonly AuthService = AuthService;
  private dialogRef: MatDialogRef<LoadingDialogComponent> | undefined

  constructor(
    private appService: AppService,
    public authService: AuthService,
    private translate: TranslateService,
    private companyService: CompanyService,
    private consentService: ConsentService,
    private dialog: MatDialog,
    private meta: Meta,
    private iconService: IconService
  ) {
    this.isHandset$ = this.appService.isMobile$
    this.consentService.init()
    this.iconService.registerIcons()

    this.translate.onLangChange.pipe(takeUntilDestroyed()).subscribe(evt => {
      this.meta.updateTag({ name: "language", content: evt.lang })
    })

    this.embedded = environment.embedded ?? false

    if (this.lang) {
      translate.setDefaultLang('en')
      translate.use(this.lang)
      Settings.defaultLocale = this.lang
    } else {
      translate.setDefaultLang('en')
      translate.use('en')
      Settings.defaultLocale = 'en'
    }
    meta.addTag({ name: "language", content: translate.currentLang })
  }

  ngOnInit(): void {
    if (this.authService.isLoggedIn()) {
      this.dialogRef = this.dialog.open(LoadingDialogComponent, { disableClose: true })
      this.companyService.validate().subscribe(r => this.handleCompanyValidationResult(r))
    }
  }

  changeLang(event: MatSelectChange) {
    let value = event.value as string
    this.translate.use(value)
    if (value == 'zh') value = 'zh_cn'
    Settings.defaultLocale = value
  }

  private handleCompanyValidationResult(d: CompanyValidationResult) {
    this.company = d?.company
    this.dialogRef?.close()
    if (d.created) window.location.reload()
  }
}
