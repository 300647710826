import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { PageNotFoundComponent } from "./error/page-not-found/page-not-found.component"
import { canActivateAuthRole } from "./auth/auth.guard"
import {
  ACCOUNTING_ADMIN,
  ADMIN,
  BETA_ACCESS, COMPANY_ADMIN, CUSTOMER_ADMIN, FEEDBACK_ADMIN,
  PROCESS_ADMIN,
  PUBLIC_ACCESS, SALES_ADMIN,
  SEARCH_ADMIN,
  SITE_ADMIN,
  SITE_READ,
  SITE_WRITE,
  STOCKLEVEL_IMPORT,
  SYSTEM_ADMIN,
  SYSTEM_READ,
  SYSTEM_WRITE
} from "./auth/roles"

let routes: Routes
routes = [
  { path: '', redirectTo: 'site', pathMatch: 'full' },
  {
    path: 'process',
    title: 'Process',
    canActivate: [canActivateAuthRole],
    data: { roles: [PROCESS_ADMIN] },
    loadChildren: () => import('./process/process.module').then(m => m.ProcessModule),
  },
  {
    path: 'accounting',
    title: 'BDN Accounting',
    canActivate: [canActivateAuthRole],
    data: { roles: [] },
    loadChildren: () => import('./accounting/accounting.module').then(m => m.AccountingModule)
  },
  {
    path: 'export',
    title: 'Data Export',
    canActivate: [canActivateAuthRole],
    loadChildren: () => import('./data-export/data-export.module').then(m => m.DataExportModule)
  },
  {
    path: 'admin',
    title: 'Administration',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    canActivate: [canActivateAuthRole],
    data: { roles: [ADMIN] }
  },
  {
    path: 'info',
    title: 'Info',
    loadChildren: () => import('./info/info.module').then(m => m.InfoModule)
  },
  {
    path: 'cart',
    title: 'Webshop Cart',
    canActivate: [canActivateAuthRole],
    loadChildren: () => import('./commerce/commerce.module').then(m => m.CommerceModule)
  },
  {
    path: 'profile',
    title: 'Profile',
    canActivate: [canActivateAuthRole],
    loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule)
  },
  {
    path: 'site',
    title: 'BDN Site',
    canActivate: [canActivateAuthRole],
    data: { roles: [SITE_READ, SITE_WRITE, SITE_ADMIN] },
    loadChildren: () => import('./site/site.module').then(m => m.SiteModule)
  },
  {
    path: 'system',
    title: 'BDN System',
    canActivate: [canActivateAuthRole],
    data: { roles: [SYSTEM_READ, SYSTEM_WRITE, SYSTEM_ADMIN] },
    loadChildren: () => import('./system/system.module').then(m => m.SystemModule)
  },
  {
    path: 'equipment',
    title: 'BDN Equipment',
    canActivate: [canActivateAuthRole],
    loadChildren: () => import('./equipment/equipment.module').then(m => m.EquipmentModule)
  },
  {
    path: 'search',
    title: 'BDN Search',
    canActivate: [canActivateAuthRole],
    loadChildren: () => import('./search/search.module').then(m => m.SearchModule)
  },
  {
    path: 'registration',
    title: 'BDN Registration',
    canActivate: [canActivateAuthRole],
    loadChildren: () => import('./registration/registration.module').then(m => m.RegistrationModule)
  },
  {
    path: 'company',
    title: 'Company',
    canActivate: [canActivateAuthRole],
    loadChildren: () => import('./company/company.module').then(m => m.CompanyModule)
  },
  {
    path: 'user',
    title: 'User',
    canActivate: [canActivateAuthRole],
    loadChildren: () => import('./user/user.module').then(m => m.UserModule)
  },
  {
    path: 'invite',
    title: 'Invite',
    canActivate: [canActivateAuthRole],
    loadChildren: () => import('./invite/invite.module').then(m => m.InviteModule)
  },
  {
    path: 'access',
    title: 'BDN Access',
    canActivate: [canActivateAuthRole],
    loadChildren: () => import('./access/access.module').then(m => m.AccessModule)
  },
  {
    path: 'portal',
    title: 'MyBITZER Portal',
    loadChildren: () => import('./portal/portal.module').then(m => m.PortalModule)
  },
  {
    path: 'backoffice',
    canActivate: [canActivateAuthRole],
    title: 'MyBITZER Back Office',
    data: { roles: [SEARCH_ADMIN, FEEDBACK_ADMIN, SALES_ADMIN, COMPANY_ADMIN, CUSTOMER_ADMIN, ACCOUNTING_ADMIN, STOCKLEVEL_IMPORT] },
    loadChildren: () => import('./backoffice/backoffice.module').then(m => m.BackofficeModule)
  },
  {
    path: 'commerce',
    title: 'BDN Commerce',
    canActivate: [canActivateAuthRole],
    loadChildren: () => import('./commerce/commerce.module').then(m => m.CommerceModule)
  },
  { path: '**', component: PageNotFoundComponent }
]

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    paramsInheritanceStrategy: 'always',
    enableTracing: false,
    bindToComponentInputs: true
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
