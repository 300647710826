import {Component, ViewChild} from '@angular/core';
import {SeriesService} from "../model/series.service";
import {ActivatedRoute} from "@angular/router";
import {SeriesInfo} from "../model/series-api";
import {
  ProductBoardContentComponent
} from "../../product/product-board-content/product-board-content.component";
import { switchMap, tap } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
    selector: 'app-series-details-products',
    templateUrl: './series-details-products.component.html',
    styleUrl: './series-details-products.component.scss',
    standalone: false
})
export class SeriesDetailsProductsComponent {

  reloading: boolean = false
  @ViewChild(ProductBoardContentComponent) content: ProductBoardContentComponent | undefined

  constructor(
    private service: SeriesService,
    private route: ActivatedRoute
  ) {
    this.route.queryParams.pipe(
      switchMap(params => {
        const seriesId = params.series ?? '';
        this.reloading = true;
        return this.service.getSeries(seriesId);
      }),
      tap(d => this.handleData(d)),
      takeUntilDestroyed()
    ).subscribe()
  }

  private handleData(d: SeriesInfo) {
    // if (this.content) this.content.updateRequest('', [], '', '', [d.id])
    this.reloading = false
  }


}
