import { Component, computed, effect, signal, ViewChild } from '@angular/core'
import { toSignal } from '@angular/core/rxjs-interop'
import { AppService } from 'src/app/app.service'
import { MatSidenav } from '@angular/material/sidenav'

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
  standalone: false
})
export class HomeComponent {
  @ViewChild('drawer') drawer!: MatSidenav

  collapsed = signal(false)
  isMobile = toSignal(this.appService.isMobile$)
  sidenavWidth = computed(() => this.setWidth(this.collapsed()))


  constructor(private appService: AppService) {
    effect(() => {
      if (this.isMobile() && this.collapsed()) {
        this.drawer.close()
      }
    })
  }

  toggleDrawer() {
    this.drawer.toggle()
    this.collapsed.set(false)
  }

  setWidth(collapsed: boolean): any {
    const collapsedWidth = this.isMobile() ? '0px' : '85px'
    return collapsed ? collapsedWidth : '300px'
  }

}
