import {Component, computed, Input, signal, ViewChild} from '@angular/core';
import {CategorySelectComponent} from "../../category/category-select/category-select.component";
import {DocumentSearchRequest, RelatedCategoryIdEntry} from "../model/document-api";
import {TranslateService} from "@ngx-translate/core";

import {PageEvent} from "@angular/material/paginator";
import {HotToastService} from "@ngxpert/hot-toast";
import {DocumentSearchResponse, DocumentTypeResultEntry} from "../../search/model/search-api";
import {
  ContentCategoryType
} from "../../../component/category/category-filter-list/category-filter-list.component";
import {ProductFacadeService} from "../../product/model/product-facade.service";
import {ProductState, SearchParams} from "../../product/model/facade.model";
import {FilterChipItems} from "../../../component/filter/filter-chips/filter-chips.component";
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {EMPTY, Observable, tap} from 'rxjs';

const DEFAULT_PAGE_SIZE = 25;

@Component({
    selector: 'app-document-board-content',
    templateUrl: './document-board-content.component.html',
    styleUrl: './document-board-content.component.scss',
    providers: [ProductFacadeService],
    standalone: false
})
export class DocumentBoardContentComponent {

  @Input() categorySelectorVisible: boolean = true
  @Input() typeSelectorVisible: boolean = true

  @Input() productId: string | undefined = undefined
  searching = computed(() => this.facadeService.searching());
  pageSize: number = DEFAULT_PAGE_SIZE
  pageIndex: number = 0
  totalSize: number = -1
  response: DocumentSearchResponse | null = null
  types = signal<DocumentTypeResultEntry[]>([])
  categories: RelatedCategoryIdEntry[] = []
  country: string = ''
  request = signal<DocumentSearchRequest>(new DocumentSearchRequest([], [], '', this.translate.currentLang, '', [], []))
  @ViewChild(CategorySelectComponent) categorySelect: CategorySelectComponent | undefined
  contentType = ContentCategoryType.DOCUMENTS;
  filterChipItems = computed<FilterChipItems | undefined>(() => {
    return {
      availableItems: this.types() || [],
      selectedItems: this.request().documentTypes || []
    }
  })

  constructor(
    private readonly facadeService: ProductFacadeService,
    private readonly translate: TranslateService,
    private readonly toast: HotToastService
  ) {
    this.load().pipe(
      tap({
        next: response => this.handleData(response),
        error: () => this.handleError()
      }),
      takeUntilDestroyed()
    ).subscribe();
  }

  handlePageEvent(event: PageEvent) {
    this.pageIndex = event.pageIndex
    this.pageSize = event.pageSize
    this.load().subscribe();
  }

  private load(request: Partial<SearchParams> = {}): Observable<ProductState> {
    if (this.searching()) return EMPTY;
    
    return this.facadeService.updateProductResponse(this.pageIndex, this.pageSize, request).pipe(
      tap({
        next: response => this.handleData(response),
        error: () => this.handleError()
      })
    );
  }

  protected handleData(response: any | null) {
    if (response) {
      this.response = response
      this.pageIndex = response.entries.pageable.number
      this.totalSize = response.entries.totalSize
      this.types.set(response.types)
      this.categories = response.categories
    } else {
      this.handleError()
    }
  }

  private handleError() {
    this.response = null
    this.pageIndex = 0
    this.totalSize = 0
    this.types.set([])
    this.categories = []
    this.toast.error("Something went wrong, please reset the search")
  }
}
