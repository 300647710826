import { Component } from '@angular/core'
import { EquipmentInfo } from "../model/equipment-api"
import { ActivatedRoute } from "@angular/router"
import { EquipmentService } from "../model/equipment.service"
import { AlarmLevelEntry } from "../../site/model/site-api"
import {
  EquipmentDetailsAlarmDialogComponent
} from "../equipment-details-alarm-dialog/equipment-details-alarm-dialog.component"
import { MatDialog } from "@angular/material/dialog"
import { AlarmService } from "../../alarm/model/alarm.service"
import { ActiveAlarmInfoEntry } from "../../alarm/model/alarm-api"
import { interval, Subscription, forkJoin, EMPTY } from "rxjs"
import { LicenseService } from "../../accounting/model/license.service"
import { LicenseInfo } from "../../accounting/model/license-api"
import { ChargeEntry } from "../../accounting/model/charge-api"
import { formatDate } from "@angular/common"
import { PropertyService } from "../../property/model/property.service"
import { PropertyDouble } from "../../property/model/property-api"
import { AccountService } from "../../accounting/model/account.service"
import { DateTime } from 'luxon'
import { filter, switchMap, tap } from "rxjs/operators"
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { ThingTemplateService } from "../../thing-template/model/thing-template.service"
import { TranslationService } from "../../translation/translation.service"
import { TranslateService } from "@ngx-translate/core"

@Component({
  selector: 'app-equipment-details-status',
  templateUrl: './equipment-details-status.component.html',
  styleUrls: ['./equipment-details-status.component.scss'],
  standalone: false
})
export class EquipmentDetailsStatusComponent {

  equipment: EquipmentInfo | undefined
  properties: Map<string, PropertyDouble> = new Map
  activeAlarmInfo: ActiveAlarmInfoEntry[] = []
  license: LicenseInfo | undefined
  charge: ChargeEntry | undefined
  lastValueUpdate: string | null | undefined
  reloadingEquipment: boolean = false
  reloadingProperties: boolean = false
  reloadingAlarms: boolean = false
  public propertyUomMap: Map<string, string> = new Map<string, string>()
  protected readonly Math = Math;
  protected readonly formatDate = formatDate;

  constructor(
    public service: EquipmentService,
    private propertyService: PropertyService,
    private alarmService: AlarmService,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private licenseService: LicenseService,
    private accountService: AccountService,
    private templateService: ThingTemplateService,
    private translationService: TranslationService,
    private translate: TranslateService
  ) {
    this.activatedRoute.paramMap.pipe(
      filter(paramsMap => !!paramsMap.get('id')),
      tap(() => this.reloadingEquipment = true),
      switchMap(paramsMap => {
        const equipmentId = Number(paramsMap.get('id'))
        this.loadPropertyTranslations(equipmentId)
        return this.service.getInfo(equipmentId).pipe(
          tap(equipment => {
            this.equipment = equipment
            this.reloadingEquipment = false
          }),
          switchMap(() => this.reloadData()),
          switchMap(() => this.getLicensingInfo(equipmentId))
        )
      }),
      takeUntilDestroyed()
    ).subscribe()

    interval(30000).pipe(switchMap(_ => this.reloadData()), takeUntilDestroyed()).subscribe()
  }

  round(data: number | undefined) {
    if (!data) return
    return Math.round(data)
  }

  getAlarmCount(alarmState: AlarmLevelEntry[]) {
    if (!alarmState) return

    let alarms = alarmState.filter(alarm => alarm.level !== 'INFO')
    return alarms.reduce((sum, alarm) => sum + alarm.amount, 0)
  }

  showActiveAlarmDialog() {
    if (!this.equipment) return
    const dialogRef = this.dialog.open(EquipmentDetailsAlarmDialogComponent, {
      data: this.equipment.equipment
    })

    dialogRef.afterClosed().subscribe()
  }

  isActivated(): boolean {
    return this.equipment?.equipment.activated ?? false
  }

  getDate(date: string) {
    return DateTime.fromISO(date).toFormat('dd/MM/yyyy')
  }

  private getLicensingInfo(equipmentId: number) {
    return this.licenseService.findByEquipment(equipmentId).pipe(
      filter(info => !!info),
      switchMap(info => {
        this.license = info
        return this.accountService.getChargeEntries(info.license.accountId, 0, 100).pipe(
          tap(charge => {
            if (!charge) return
            this.charge = charge.content.find(entry => entry.licenseId === info.license.id)
          })
        )
      })
    )
  }

  private reloadData() {
    if (!this.equipment) return EMPTY

    return forkJoin({
      equipment: this.reloadEquipment(),
      properties: this.reloadProperties(),
      alarms: this.reloadAlarms()
    })
  }

  private reloadEquipment() {
    if (!this.equipment || this.reloadingEquipment) {
      return EMPTY
    }
    this.reloadingEquipment = true
    return this.service.getInfo(this.equipment.equipment.id).pipe(
      tap(info => {
        this.equipment = info
        this.reloadingEquipment = false
      })
    )
  }

  private reloadProperties() {
    if (!this.equipment || this.reloadingProperties) {
      return EMPTY
    }
    this.reloadingProperties = true
    return this.propertyService.getLastDoubleFavorite(this.equipment.equipment.id).pipe(
      tap(data => {
        let values = Object.entries(data)
        this.properties = new Map(values)

        let timestamps = values.map(([key, value]) => DateTime.fromISO(value.timestamp + 'Z', { zone: 'utc' }))
        this.lastValueUpdate = timestamps.length <= 0 ? undefined : DateTime.max(...timestamps).toISO()
        this.reloadingProperties = false
      })
    )
  }

  private reloadAlarms() {
    if (!this.equipment || this.reloadingAlarms) {
      return EMPTY
    }
    this.reloadingAlarms = true
    return this.alarmService.getActiveAlarmInfo(this.equipment.equipment.id).pipe(
      tap(data => {
        this.activeAlarmInfo = data
        this.reloadingAlarms = false
      })
    )
  }

  private loadPropertyTranslations(equipmentId: number) {
    return forkJoin([
      this.templateService.getEquipmentTemplate(equipmentId),
      this.translationService.getUOMTranslations(this.translate.currentLang)
    ]).pipe(
      switchMap(([template, translations]) => {
        const entries = new Map(Object.entries(translations.entries))
        const definitions = template.categories.map(c => c.properties.map(p => p))
        const properties = definitions.reduce(function (prev, next) {
          return prev.concat(next)
        })

        this.propertyUomMap = new Map<string, string>(properties.map(p =>
          [p.name, entries.get(p.unitOfMeasure) ?? '']
        ))
        return []
      })
    ).subscribe()
  }

  trimPropertyName(name: string) {
    const property = name.replace('BDN.', '')
    return property.charAt(0).toLowerCase() + property.slice(1)
  }
}