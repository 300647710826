import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Observable, Subject, EMPTY } from "rxjs";
import { debounceTime, tap, switchMap, filter, catchError } from "rxjs/operators";
import { KnowledgeService } from "../../registration/model/knowledge.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Equipment, EquipmentUpdateRequest } from "../model/equipment-api";
import { EquipmentService } from "../model/equipment.service";
import { ModuleService } from "../../module/model/module.service";
import { ElectronicModule, ElectronicModuleValidationRequest } from "../../module/model/module-api";
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
    selector: 'app-change-equipment-dialog',
    templateUrl: './change-equipment-dialog.component.html',
    styleUrls: ['./change-equipment-dialog.component.scss'],
    standalone: false
})
export class ChangeEquipmentDialogComponent {

  equipmentData: FormGroup | undefined
  filteredTypes: Observable<string[]> | undefined
  refrigerants: string[] = []
  electronicModules: string[] = []
  private subject: Subject<string> = new Subject();
  private serialNumberChange: Subject<string> = new Subject()
  loading = false

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Equipment,
    private fb: FormBuilder,
    private service: EquipmentService,
    private moduleService: ModuleService,
    private knowledgeService: KnowledgeService,
    private dialogRef: MatDialogRef<ChangeEquipmentDialogComponent>
  ) {
    this.serialNumberChange.pipe(
      debounceTime(1000),
      switchMap(serialNumber => this.serialNumberValidation(serialNumber)),
      takeUntilDestroyed()
    ).subscribe();

    this.knowledgeService.subject.pipe(
      tap(data => this.refrigerants = data),
      takeUntilDestroyed()
    ).subscribe();
  }

  ngOnInit(): void {
    if (!this.data) return;
    
    this.moduleService.findByEquipmentId(this.data.id).pipe(
      tap(modules => this.handleModule(modules.content))
    ).subscribe();

    this.refrigerants = this.knowledgeService.loadRefrigerants()
    this.electronicModules = this.knowledgeService.getElectronicModules()
    this.equipmentData = this.fb.group({
      name: this.fb.control(this.data?.name, [Validators.required]),
      refrigerant: this.fb.control(this.data?.refrigerant, [Validators.required]),
      serialnumber: this.fb.control(this.data?.serialNumber, [Validators.required]),
      type: this.fb.control(this.data?.productId, [Validators.required]),
      electronicModuleType: this.fb.control(''),
      electronicModuleSN: this.fb.control(''),
    })

    this.subject.pipe(
      debounceTime(1000),
      switchMap(searchTextValue => {
        this.loading = true;
        return this.knowledgeService.getTypes(searchTextValue).pipe(
          tap(result => {
            this.loading = false;
            if (!result.includes(searchTextValue)) {
              this.equipmentData?.get('type')?.setErrors({ incorrectType: { value: true } });
            } else {
              this.equipmentData?.get('type')?.setErrors(null);
            }
          })
        );
      })
    ).subscribe();
  }

  private handleModule(content: ElectronicModule[]) {
    if (!content || !content.length) return
    let eModule = content[0]
    this.equipmentData?.get('electronicModuleType')?.setValue(eModule.type)
    this.equipmentData?.get('electronicModuleSN')?.setValue(eModule.serialNumber)

  }


  update() {
    if (this.equipmentData?.invalid) return;
    
    const values = this.equipmentData?.value;
    const request = new EquipmentUpdateRequest(
      values.name ?? "",
      values.refrigerant ?? "",
      values.type ?? "",
      values.serialnumber ?? "",
      values.electronicModuleType ?? "",
      values.electronicModuleSN ?? ""
    );

    if (request) {
      this.service.changeEquipment(this.data.id, request).pipe(
        filter(it => !!it),
        tap(() => this.dialogRef.close(true))
      ).subscribe();
    }
  }

  onKeyUp(searchTextValue: string) {
    this.subject.next(searchTextValue);
  }

  onSerialNumberChange(newValue: string) {
    this.serialNumberChange.next(newValue)
  }

  onModuleTypeChange(moduleType: string) {
    const moduleTypes = ['CM-RC-01', 'CM-SW-01', 'SE-i1', 'CM-RC-02']
    const serialNumber = this.equipmentData?.get('serialnumber')?.value
    const electronicSerialNumber = this.equipmentData?.get('electronicModuleSN')

    if (electronicSerialNumber?.value && electronicSerialNumber?.value !== serialNumber) return

    if (moduleTypes.includes(moduleType)) {
      electronicSerialNumber?.setValue('')
      return
    }

    electronicSerialNumber?.setValue(serialNumber)
  }

  findType() {
    const serialNumber = this.equipmentData?.get('serialnumber')?.value || '';
    this.knowledgeService.getTypeBySerialNumber(serialNumber).pipe(
      tap((data: any) => this.equipmentData?.get('type')?.setValue(data.value))
    ).subscribe();
  }

  private serialNumberValidation(serialNumber: string): Observable<boolean> {
    const moduleType = this.equipmentData?.get('electronicModuleType')?.value;
    if (!moduleType) {
      this.handleSerialNumberValidationResult(true);
      return EMPTY;
    }

    return this.moduleService.validate(
      new ElectronicModuleValidationRequest(moduleType, serialNumber)
    ).pipe(
      tap(value => this.handleSerialNumberValidationResult(value)),
      catchError(err => {
        this.handleSerialNumberValidationResult(false);
        return EMPTY;
      })
    );
  }

  private handleSerialNumberValidationResult(result: boolean) {
    if (!this.equipmentData) return
    if (result) {
      this.equipmentData.setErrors(null)
    } else {
      this.equipmentData.get('electronicModuleSN')?.setErrors({ incorrectSerialNumber: { value: true } })
    }
  }
}
